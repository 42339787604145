<template>
  <div class="setting-page">
    <div class="ft20 cl-main ftw600">门店管理</div>

    <div class="form-search-box mt20">
      <a-form layout="inline">
        <a-form-item label="门店名称">
          <a-input v-model="search.keyword" placeholder="请输入门店名称"></a-input>
        </a-form-item>

        <a-form-item>
          <a-button @click="searchAct" type="primary">查询</a-button>
          <a-button @click="cancelAct" class="ml10">取消</a-button>
        </a-form-item>
      </a-form>
    </div>

    <div class="mt20">
      <a-button type="primary" @click="addAct">新增门店</a-button>
    </div>
    <div class="mt20">
      <div class="wxb-table-white">
        <!-- 表格 -->
        <a-table rowKey="mendian_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
          :data-source="datas" :loading="loading">
          <template slot="names" slot-scope="record">
            <div class="name" @click="jump(record)">
              <!-- {{record.name}} -->
              {{ record.name }}
            </div>
          </template>

          <template slot="pois" slot-scope="record,index">
            <div>
              {{ record.lat }}/{{ record.lng }}
            </div>
          </template>
          <template slot="bind" slot-scope="record">
            <div style="color: blue;">{{ record.bind_mobile }}</div>
            <div style="color: green;">{{ record.bind_mobile2 }}</div>
          </template>
          <template slot="photo" slot-scope="record">
            <div>
              <!-- <img class="swiper_item" :src="item" alt="" v-for="(item,i) in record " :key="i"> -->
              <img class="swiper_item" :src="record[0]" alt="">
            </div>
          </template>

          <template slot="action" slot-scope="record,index">
            <div class="flex center">
              <a-dropdown placement="bottomRight">
                <span class="more-act">
                  <i class="iconfont iconmore_gray"></i>
                </span>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="editAct(record)">
                      <span>编辑门店</span>
                    </a>
                  </a-menu-item>

                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="delAct(record)">
                      <span>删除门店</span>
                    </a>
                  </a-menu-item>

                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <div>
      <mendian-form v-if="showForm" @ok="formOk" @closed="formClosed" :datas="detail"></mendian-form>
    </div>

  </div>
</template>

<script>
import { listMixin } from "../../common/mixin/list.js";
import mendianForm from "./components/mendian/form.vue";

export default {
  mixins: [listMixin],
  components: {
    mendianForm,
  },
  computed: {
    checkCanAdd() {
      return this.maxOpenNum > this.pagination.total;
    },
  },
  data() {
    return {
      loading: false,
      showForm: false,
      detail: {
        mendian_id: 0,
        name: "",
        tel: "",
        lng: "",
        lat: "",
        address: "",
      },
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      search: {
        keyword: "",
      },
      maxOpenNum: 0,
      columns: [
        { title: "门店ID", dataIndex: "mendian_id", align: "center" },
        {
          title: "门店名称",
          key: "names",
          align: "center",
          scopedSlots: { customRender: "names" },
        },
        {
          title: "轮播图",
          dataIndex: "photo",
          align: "center",
          scopedSlots: { customRender: "photo" },
        },
        { title: "门店地址", dataIndex: "address", align: "center" },
        { title: "门店电话", dataIndex: "tel", align: "center" },
        { title: "介绍详情", dataIndex: "info", align: "center" },
        {
          title: "店长账号",
          key: "bind",
          align: "center",
          scopedSlots: { customRender: "bind" },
        },
        // { title: "初始时限", dataIndex: "begin_time1", align: "center" },
        // { title: "时限内每分钟金额", dataIndex: "begin_num1", align: "center" },
        // { title: "超出每分钟金额", dataIndex: "num1", align: "center" },
        // { title: "(闲时)初始时限", dataIndex: "begin_time2", align: "center" },
        // { title: "(闲时)时限内每分钟金额", dataIndex: "begin_num2", align: "center" },
        // { title: "(闲时)超出每分钟金额", dataIndex: "num2", align: "center" },

        // {
        //   title: "地理坐标",
        //   key: "pois",
        //   align: "center",
        //   scopedSlots: { customRender: "pois" },
        // },
        // { title: "经度", dataIndex: "lng", align: "center" },
        // { title: "纬度", dataIndex: "lat", align: "center" },
        // { title: "工位数量", dataIndex: "gongwei", align: "center" },

        { title: "充值⾦额", dataIndex: "recharge_price", align: "center" },
        { title: "充值⼈数", dataIndex: "num", align: "center" },
        { title: "充值次数", dataIndex: "count", align: "center" },
        { title: "总计可提现金额", dataIndex: "recharge_price2", align: "center" },
        { title: "已提现金额（含提现成功及待审核）", dataIndex: "withdrawal_price", align: "center" },
        { title: "未提现金额", dataIndex: "else_price", align: "center" },

        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      datas: [],
    };
  },
  created() {
    this.getLists();
    //做你要做的事情
  },
  methods: {
    jump(itme) {
      this.$router.push({
        name: "mendian_details",
        params: {
          itme,
        },
      });
    },
    addAct() {
      this.detail = {
        mendian_id: 0,
        name: "",
        tel: "",
        lng: "",
        lat: "",
        address: "",
        begin_time: "",
        begin_num: "",
        num: "",
        gongwei: "",
      };
      this.showForm = true;
      // if (this.checkCanAdd) {

      // } else {
      //   this.$error({
      //     title: "错误提示",
      //     content: "您可最多可创建" + this.maxOpenNum + "个门店",
      //   });
      // }
    },
    editAct(res) {
      this.detail = res;
      this.showForm = true;
    },
    formOk() {
      this.showForm = false;
      this.getLists();
    },
    formClosed() {
      this.showForm = false;
    },
    delAct(record) {
      this.$confirm({
        title: "确认删除这个门店吗？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .api("admin/delMendian", {
                mendian_id: record.mendian_id,
              })
              .then((res) => {
                this.$message.success("删除成功");
                this.getLists();
              })
              .catch((res) => {
                console.log(res);
              })
              .finally(() => {
                resolve();
              });
          });
        },
      });
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("admin/getMendianList", {
          keyword: this.search.keyword,
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        })
        .then((res) => {
          this.pagination.total = res.total;
          let newArr = []
          // this.datas = res.list
          this.datas = res.list.map(item=>{
            item.lng = this.qqMapTransBMapLng(item.lng,item.lat)
            item.lat = this.qqMapTransBMapLat(item.lng,item.lat)
            newArr.push(item)
          })
          this.datas = newArr
          this.maxOpenNum = res.store_num;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    qqMapTransBMapLng(lng, lat) {
      let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
      let x = lng;
      let y = lat;
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
      let lngs = z * Math.cos(theta) + 0.0065;
      // let lats = z * Math.sin(theta) + 0.006;
      return lngs.toFixed(6)
    },
    qqMapTransBMapLat(lng, lat) {
      let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
      let x = lng;
      let y = lat;
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
      // let lngs = z * Math.cos(theta) + 0.0065;
      let lats = z * Math.sin(theta) + 0.006;
      return lats.toFixed(6)
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },
    searchCancel() {
      this.search = {
        keyword: "",
      };
    },
    searchAct() {
      this.getLists();
    },
    cancelAct() {
      this.searchCancel();
      this.searchAct();
    },
  },
};
</script>

<style>
.setting-page {
  min-width: 1080px;
}

.swiper_item {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.name {
  cursor: pointer;
  color: #4772ff;
}</style>