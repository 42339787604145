<template>
  <div>

    <a-modal :title="form.mendian_id > 0 ? '编辑门店' : '新增门店'" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <a-form-model :model="form">

        <a-form-model-item required label="门店名称">
          <a-input v-model="form.name" placeholder="输入门店名称" style="width: 300px;"></a-input>
        </a-form-model-item>
        <a-form-model-item required label="门店电话">
          <a-input v-model="form.tel" placeholder="输入门店电话" style="width: 300px;"></a-input>
        </a-form-model-item>

        <a-form-model-item required label="店长账号">
          <a-input v-model="form.bind_mobile" placeholder="输入店长1手机号" style="width: 300px;"></a-input>
          <a-input v-model="form.bind_mobile2" placeholder="输入店长2手机号" style="width: 300px;"></a-input>
        </a-form-model-item>

        <a-form-model-item required label="门店地址">
          <a-input v-model="form.address" placeholder="输入门店地址" style="width: 300px;"></a-input>
        </a-form-model-item>
        <a-form-model-item required label="工位数量">
          <a-input v-model="form.gongwei" placeholder="输入工位数量" style="width: 300px;"></a-input>
        </a-form-model-item>

        <a-form-model-item required label="6:00-00:00:初始时限">
          <a-input v-model="form.begin_time1" placeholder="输入初始时限" style="width: 300px;"></a-input>
        </a-form-model-item>
        <a-form-model-item required label="6:00-00:00:时限内每分钟金额">
          <a-input-number v-model="form.begin_num1" :min="0" placeholder="输入6:00-00:00:时限内每分钟金额" :step="0.01" style="width: 300px;" />
        </a-form-model-item>
        <a-form-model-item required label="6:00-00:00:超出每分钟金额">
          <a-input-number v-model="form.num1" :min="0" placeholder="输入6:00-00:00:超出每分钟金额" :step="0.01" style="width: 300px;" />
        </a-form-model-item>

        <a-form-model-item required label="(闲时)00:00-6:00:初始时限">
          <a-input v-model="form.begin_time2" placeholder="输入初始时限" style="width: 300px;"></a-input>
        </a-form-model-item>
        <a-form-model-item required label="(闲时)00:00-6:00:时限内每分钟金额">
          <a-input-number v-model="form.begin_num2" :min="0" placeholder="输入00:00-6:00:时限内每分钟金额" :step="0.01" style="width: 300px;" />
        </a-form-model-item>
        <a-form-model-item required label="(闲时)00:00-6:00:超出每分钟金额">
          <a-input-number v-model="form.num2" :min="0" placeholder="输入00:00-6:00:超出每分钟金额" :step="0.01" style="width: 300px;" />
        </a-form-model-item>

        <a-form-model-item required label="门店定位">
          <div class="flex alcenter">
            <a-input :value="form.lat + ',' + form.lng" disabled style="width: 200px; margin-right: 10px; color: #000000;"></a-input>
            <baidu-map :lat="form.lat" :lng="form.lng" @select="selectAct"></baidu-map>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import BaiduMap from "../../../../components/map/baidumap.vue";
export default {
  components: {
    BaiduMap,
  },
  props: {
    datas: {
      type: Object,
      default: function () {
        return new Object();
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      visible: true,
      form: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.datas));
    this.visible = true;
  },
  methods: {
    selectAct(res) {
      this.form.lng = res.lng;
      this.form.lat = res.lat;
    },
    handleOk() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("admin/saveMendian", {
          mendian_id: this.form.mendian_id,
          lng: this.form.lng,
          lat: this.form.lat,
          address: this.form.address,
          tel: this.form.tel,
          name: this.form.name,
          gongwei: this.form.gongwei,
          // begin_time: this.form.begin_time,
          // begin_num: this.form.begin_num,
          // num: this.form.num,
          begin_time1: this.form.begin_time1,
          begin_num1: this.form.begin_num1,
          num1: this.form.num1,
          begin_time2: this.form.begin_time2,
          begin_num2: this.form.begin_num2,
          num2: this.form.num2,
          bind_mobile: this.form.bind_mobile,
          bind_mobile2: this.form.bind_mobile2,
        })
        .then((res) => {
          this.$message.success("操作成功");
          this.confirmLoading = false;
          this.visible = false;
          this.$emit("ok");
        })
        .catch((res) => {
          this.confirmLoading = false;
        });
    },
    handleCancel() {
      this.visible = false;
      this.$emit("closed");
    },
  },
};
</script>

<style>
</style>